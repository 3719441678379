<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Invoices for {{ monthName}} {{ year }}</h1>
        <p>Invoices held in the IMS data for the period selected</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-date-picker
          v-model="date"
          type="month"
        ></v-date-picker>
      </v-col>
      <v-col class="col-9">
        <v-tabs v-model="tab">
          <v-tab>IMS Invoices</v-tab>
          <v-tab>IMS Applications</v-tab>
          <v-tab>Sage</v-tab>
          <v-tab>Differences</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row class="pt-2 pb-2">
              <v-col>
                <h3>Invoice value for {{ monthName }} {{ year }}: {{ total | priceInPounds | currency }}</h3>
              </v-col>
              <v-col>
                <download-csv
                  class="float-right"
                  name="onims.csv"
                  :data="invoices">
                  <v-btn small>Export</v-btn>
                </download-csv>
              </v-col>
            </v-row>  
            <v-simple-table v-if="invoices.length > 0" fixed-header height="400">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice, index) in invoices" :key="index">
                  <td>{{ invoice.invoiceNumber }}</td>
                  <td>{{ invoice.date }}</td>
                  <td>{{ invoice.customer }}</td>
                  <td>{{ invoice.value | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-skeleton-loader
              v-else
              v-bind="attrs"
              type="table: table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-tab-item>
          <v-tab-item>
            <v-row class="pt-2 pb-2">
              <v-col>
                <h3>Application value for {{ monthName }} {{ year }}: {{ applicationTotal | priceInPounds | currency }}</h3>
              </v-col>
              <v-col>
                <download-csv
                  class="float-right"
                  name="onims.csv"
                  :data="applications">
                  <v-btn small>Export</v-btn>
                </download-csv>
              </v-col>
            </v-row>  
            <v-simple-table v-if="applications.length > 0" fixed-header height="400">
              <thead>
                <tr>
                  <th>Application Number</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(application, index) in applications" :key="index">
                  <td>{{ application.invoiceNumber }}</td>
                  <td>{{ application.date }}</td>
                  <td>{{ application.customer }}</td>
                  <td>{{ application.value | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-skeleton-loader
              v-else
              v-bind="attrs"
              type="table: table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-tab-item>
          <v-tab-item>
            <v-row class="pt-2 pb-2">
              <v-col>
                <h3>Invoice value for {{ monthName }} {{ year }}: {{ sageTotal | currency }}</h3>
              </v-col>
              <v-col>
                <download-csv
                  class="float-right"
                  name="onsage.csv"
                  :data="sageInvoices">
                  <v-btn small>Export</v-btn>
                </download-csv>
              </v-col>
            </v-row>  
            <v-simple-table v-if="sageInvoices.length > 0" fixed-header height="400">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice, index) in sageInvoices" :key="index">
                  <td>{{ invoice.invoiceNumber }}</td>
                  <td>{{ invoice.date }}</td>
                  <td>{{ invoice.customer }}</td>
                  <td>{{ invoice.value | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-skeleton-loader v-else
              v-bind="attrs"
              type="table: table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-tab-item>
          <v-tab-item>
            <v-row class="pt-2 pb-2">
              <v-col>
                <h3>Invoices which are on Sage but not on the IMS</h3>
              </v-col>
              <v-col>
                <download-csv
                  class="float-right"
                  name="differences.csv"
                  :data="differenceInvoices">
                  <v-btn small>Export</v-btn>
                </download-csv>
              </v-col>
            </v-row>  
            <v-simple-table v-if="differenceInvoices.length > 0" fixed-header height="400">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice, index) in differenceInvoices" :key="index">
                  <td>{{ invoice.invoiceNumber }}</td>
                  <td>{{ invoice.date }}</td>
                  <td>{{ invoice.customer }}</td>
                  <td>{{ invoice.value | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-skeleton-loader
              v-else
              v-bind="attrs"
              type="table: table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ReportsInvoicedTotalsByMonth',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      applicationTotal: 0,
      applications: [],
      invoices: [],
      sageInvoices: [],
      differenceInvoices: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      monthName: '',
      year: '',
      total: 0,
      sageTotal: 0,
      tab: null,
      attrs: {
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  watch: {
    date() {
      this.getInvoiceTotals();
    }
  },
  methods: {
    getInvoiceTotals() {
      this.applicationTotal = 0;
      this.applications = [];
      this.total = 0;
      this.invoices = [];
      this.sageTotal = 0;
      this.sageInvoices = [];
      this.monthName = 'loading';
      this.year = '';
      const postData = {};
      postData.date = this.date;
      axios.post(`/invoices/getInvoiceTotals.json?token=${this.token}`, postData)
      .then((response) => {
        this.applicationTotal = response.data.applicationTotal;
        this.total = response.data.total;
        this.applications = response.data.applications;
        this.invoices = response.data.invoices;
        this.sageTotal = response.data.sageTotal;
        this.sageInvoices = response.data.sageInvoices;
        this.differenceInvoices = response.data.differenceInvoices;
        this.monthName = response.data.monthName;
        this.year = response.data.year;
      });
    },
  },
  mounted() {
    this.getInvoiceTotals();
  },
};
</script>
  